<template>
    <section class="offers-section" :style="sectionStyle">
        <div class="container px-4 xl:px-0 mx-auto">
            <h2 v-if="blok.Title" class="offers-section__title" :style="titleStyle">
                {{ blok.Title }}
            </h2>
            <div v-if="blok.Subtitle" class="offers-section__subtitle">{{ blok.Subtitle }}</div>
            <div class="offers-section__wrapper">
                <div v-for="offer in blok.OfferCards" :key="offer._uid" class="offers-section__item">
                    <div class="offer-card">
                        <NuxtLink :to="getInternalLink(offer.Link)" class="offer-card__image">
                            <NuxtImg
                                :src="offer.Image.filename"
                                :alt="offer.Image.alt"
                                sizes="sm:400px md:800px lg:1024px xl:1920px"
                                loading="lazy"
                                provider="storyblok"
                                format="webp"
                            />
                        </NuxtLink>
                        <div class="offer-card__content">
                            <div class="offer-card__text">{{ offer.Text }}</div>
                            <div class="offer-card__btn-wrapper">
                                <UiAdvancedBtn
                                    v-if="buttonType === 'advanced'"
                                    :to="getInternalLink(offer.Link)"
                                    component-variant="secondary"
                                    component-type="link"
                                    :with-arrow="true"
                                >
                                    {{ offer.Title }}
                                </UiAdvancedBtn>
                                <NuxtLink v-else class="offer-card__btn" :to="getInternalLink(offer.Link)">{{
                                    offer.Title
                                }}</NuxtLink>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script setup lang="ts">
import type { IOffersSection } from '~ui/types/components/OffersSection';

const { blok, buttonType } = defineProps<{ blok: IOffersSection; buttonType: 'simple' | 'advanced' }>();

const sectionStyle = computed(() => {
    return {
        backgroundColor: blok.BackgroundColor?.color || null,
    };
});

const titleStyle = computed(() => {
    return {
        '--title-color': blok.titleColor?.color || null,
    };
});
</script>

<style lang="postcss" scoped>
.offer-card {
    &__image {
        img {
            @apply w-full h-full object-cover;
        }
    }
}
</style>
